<template>
  <div class="district-list">
    <div class="is-flex is-justify-content-flex-end">
      <el-button icon="el-icon-plus" type="primary" @click="createDistrict">
        Добавить район
      </el-button>
    </div>
    <el-table :data="data" style="width: 100%">
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="title" label="Название" />
      <el-table-column width="120">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" icon="el-icon-edit" @click="updateDistrict(row)" />
          <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteDistrict(row)" />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <district-create ref="createDistrictRef" />
    <district-update ref="updateDistrictRef" />
  </div>
</template>
<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { DISTRICT_STORE_MODULE_NAME, districtsStoreModule } from './districtsStoreModule';
import { useDistrictList } from './useDistrictList';
import DistrictCreate from './district-create/district-create.vue';
import DistrictUpdate from './district-update/district-update.vue';

export default {
  components: { DistrictCreate, DistrictUpdate },
  setup() {
    if (!store.hasModule(DISTRICT_STORE_MODULE_NAME)) {
      store.registerModule(DISTRICT_STORE_MODULE_NAME, districtsStoreModule);
    }
    onUnmounted(() => {
      if (store.hasModule(DISTRICT_STORE_MODULE_NAME)) {
        store.unregisterModule(DISTRICT_STORE_MODULE_NAME);
      }
    });

    const {
      data,
      meta,
      changePage,
      createDistrictRef,
      createDistrict,
      updateDistrictRef,
      updateDistrict,
      deleteDistrict,
    } = useDistrictList();

    return {
      data,
      meta,
      changePage,
      createDistrictRef,
      createDistrict,
      updateDistrictRef,
      updateDistrict,
      deleteDistrict,
    };
  },
};
</script>
