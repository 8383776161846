import { httpClient, parseQueryParam } from '@/app/shared/services';

export const DISTRICT_STORE_MODULE_NAME = 'district';

export const districtsStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {
    autocompleteDistricts: (state) => state.data.map((p) => ({ value: p.id, label: p.title })),
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchDistricts({ commit }, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam(queryParams);
        httpClient
          .get(`/districts${params}`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_DATA', data);
            commit('SET_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    updateDistrict(_, { id, payload }) {
      return new Promise((res, rej) => {
        httpClient
          .put(`/districts/${id}`, payload)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    createDistrict({ commit, state }, payload) {
      return new Promise((res, rej) => {
        httpClient
          .post('/districts', payload)
          .then((response) => {
            const { data } = response.data;
            const newData = state.data;
            newData.push(data);
            commit('SET_DATA', newData);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    deleteDistrict({ commit, state }, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/districts/${id}`)
          .then((response) => {
            commit(
              'SET_DATA',
              state.data.filter((item) => item.id !== id)
            );
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
