<template>
  <el-dialog title="Обновление района" :visible.sync="isOpen" width="30%">
    <el-form
      v-if="district"
      ref="formUpdateRef"
      :model="district"
      :rules="rules"
      label-width="100px"
      label-position="top"
    >
      <el-form-item label="Название" prop="title">
        <el-input v-model="district.title" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Закрыть</el-button>
      <el-button type="primary" @click="saveDistrict">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useDistrictUpdate } from './useDistrictUpdate';

export default {
  name: 'DisctrictUpdate',
  setup() {
    const { isOpen, open, district, saveDistrict, rules, formUpdateRef } = useDistrictUpdate();

    return {
      isOpen,
      open,
      district,
      saveDistrict,
      rules,
      formUpdateRef,
    };
  },
};
</script>
