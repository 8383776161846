import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { DISTRICT_STORE_MODULE_NAME } from '../districtsStoreModule';

export function useDistrictCreate() {
  const isOpen = ref(false);
  const district = ref({});
  const formСreateRef = ref(null);

  const rules = {
    title: [{ required: true, message: 'Укажите название района', trigger: 'change' }],
  };

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveDistrict = () => {
    try {
      const { title } = district.value;
      formСreateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/createDistrict`, { title });
          createDialogController.resolve(true);
          isOpen.value = false;
          district.value = {};
          return;
        }
        return false;
      });
    } catch (e) {
      createDialogController.reject(false);
    }
  };

  return {
    isOpen,
    open,
    district,
    saveDistrict,
    rules,
    formСreateRef,
  };
}
