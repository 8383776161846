import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { DISTRICT_STORE_MODULE_NAME } from '../districtsStoreModule';

export function useDistrictUpdate() {
  const isOpen = ref(false);
  const district = ref('');
  const formUpdateRef = ref(null);

  const rules = {
    title: [{ required: true, message: 'Укажите название района', trigger: 'change' }],
  };

  let updateDialogController = null;

  const open = (p) => {
    district.value = p;
    let resolve;
    let reject;
    const updateDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    updateDialogController = { resolve, reject };
    isOpen.value = true;
    return updateDialogPromise;
  };

  const saveDistrict = () => {
    try {
      const { id, title } = district.value;
      formUpdateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/updateDistrict`, {
            id,
            payload: { title },
          });
          updateDialogController.resolve(true);
          isOpen.value = false;
          return;
        }
        return false;
      });
    } catch (e) {
      updateDialogController.reject(false);
      isOpen.value = false;
    }
  };

  return {
    isOpen,
    open,
    district,
    saveDistrict,
    rules,
    formUpdateRef,
  };
}
