import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { DISTRICT_STORE_MODULE_NAME } from './districtsStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useDistrictList() {
  const createDistrictRef = ref(null);
  const updateDistrictRef = ref(null);

  const meta = computed(() => store.state[DISTRICT_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[DISTRICT_STORE_MODULE_NAME].data);

  const fetchDistrict = (params) => {
    store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/fetchDistricts`, params);
  };

  const changePage = (page) => {
    router.replace({ query: { ...routeData.query, page } });
    fetchDistrict({
      page,
    });
  };

  const createDistrict = async () => {
    const createResult = await createDistrictRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Район успешно добавлен',
      });
    }
  };

  const updateDistrict = async (d) => {
    const updateResult = await updateDistrictRef.value.open(d);
    if (updateResult) {
      Notification.success({
        title: 'Успех',
        message: 'Район успешно обновлен',
      });
    }
  };

  const deleteDistrict = ({ id }) => {
    try {
      store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/deleteDistrict`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Район успешно удален.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  fetchDistrict({ page: routeData.query.page || 1 });

  return {
    data,
    meta,
    changePage,
    createDistrictRef,
    createDistrict,
    updateDistrictRef,
    updateDistrict,
    deleteDistrict,
  };
}
